import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueRouterLayout from 'vue-router-layout'
import axios from 'axios'
import VueAxios from 'vue-axios'
import kernel from './plugins/kernel'
import VueCookies from 'vue3-cookies'
import Vuex from 'vuex'
import jQuery from 'jquery'
import VueLazyLoad from 'vue3-lazyload'
import VueEasyLightbox from 'vue-easy-lightbox'

// import animated from 'animate.css' 
import '@/assets/css/all.scss'

// Register it globally
const app = createApp(App)
app
.use(VueRouterLayout)
.use(router)
.use(VueAxios, axios)
.use(kernel)
.use(Vuex)
.use(jQuery)
.use(VueCookies)
.use(VueLazyLoad, {log:false})
.use(VueEasyLightbox)

app.mount('#tamsui')