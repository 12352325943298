import { createRouter, createWebHistory } from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout(layout => {
    return import('@/layouts/' + layout + '.vue')
})

const router = createRouter({  
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    },    
    {
      path: '/',
      name: 'home',
      component: RouterLayout,
      meta: {
        title: '首頁'
      },
      children: [
        {
          path: "/8views",
          name: "8views",
          meta: {
            title: '緣起 / 淡水繽紛八景'
          },
          component:() => import('@/pages/8views.vue'),
        },
        {
          path: "/history",
          name: "history",
          meta: {
            title: '緣起 / 淡水八景從哪來'
          },
          component:() => import('@/pages/history.vue'),
        },
        {
          path: "/traffic4.0",
          name: "traffic4.0",
          meta: {
            title: '緣起 / 淡水八景4.0'
          },
          component:() => import('@/pages/traffic4.0.vue'),
        },
        {
          path: "/intro",
          name: "intro",
          meta: {
            title: '活動辦法'
          },
          component:() => import('@/pages/intro.vue'),
        },
        {
          path: "/scene",
          name: "scene",
          meta: {
            title: '全民推薦大搜密'
          },
          component:() => import('@/pages/scene.vue'),
        },
        {
          path: "/outstanding",
          name: "outstanding",
          meta: {
            title: '絕景攝影邀請賽 / 作品欣賞'
          },
          component:() => import('@/pages/outstanding.vue'),
        },
        {
          path: "/awards",
          name: "awards",
          alias: '/awards/:ID/:share',
          meta: {
            title: '絕景攝影邀請賽 / 得獎作品'
          },
          component:() => import('@/pages/awards.vue'),
        },
        {
          path: "/vote",
          name: "vote",
          alias: '/vote/:ID/:share',
          meta: {
            title: '八景4.0全民票選抽大獎'
          },
          component:() => import('@/pages/vote.vue'),
        },
        {
          path: "/tourist-guide",
          name: "tourist-guide",
          meta: {
            title: '精彩玩法路線圖'
          },
          component:() => import('@/pages/tourist-guide.vue'),
          children: [
            {
              path: "/tourist-guide/1",
              name: "1",
              meta: {
                title: '尋古探今，有機食農樂'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/2",
              name: "2",
              meta: {
                title: '漫遊山林，花漾小旅行'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/3",
              name: "3",
              meta: {
                title: '滬尾采風，夕照逍遙遊'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/4",
              name: "4",
              meta: {
                title: '歷史足跡，河岸漫遊趣'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/5",
              name: "5",
              meta: {
                title: '探索紅林，移民大解秘'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/6",
              name: "6",
              meta: {
                title: '尋寶滬尾，虔心祈福行'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/7",
              name: "7",
              meta: {
                title: '穿越埔頂，古蹟探訪樂'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
            {
              path: "/tourist-guide/8",
              name: "8",
              meta: {
                title: '樂活漫遊，悠然輕旅行'
              },
              component:() => import('@/pages/tourist-guide.vue'),
            },
          ]
        },
        {
          path: "/eat",
          name: "eat",
          meta: {
            title: '吃什麼'
          },
          component:() => import('@/pages/enjoy.vue'),
        },
        {
          path: "/play",
          name: "play",
          meta: {
            title: '玩什麼'
          },
          component:() => import('@/pages/enjoy.vue'),
        },
        {
          path: "/stay",
          name: "stay",
          meta: {
            title: '住哪裡'
          },
          component:() => import('@/pages/enjoy.vue'),
        },
        {
          path: "/buy",
          name: "buy",
          meta: {
            title: '歐咪呀給哪裡買'
          },
          component:() => import('@/pages/enjoy.vue'),
        },
        {
          path: "/sponsor",
          name: "sponsor",
          meta: {
            title: '贊助商列表'
          },
          component:() => import('@/pages/sponsor.vue'),
        },
        {
          path: "/winner-list",
          name: "winner-list",
          meta: {
            title: '全民票選抽大獎獲獎名單'
          },
          component:() => import('@/pages/winner-list.vue'),
        },
        {
          path: '/:pathMatch(.*)*',
          name: "404",
          meta: {
            title: '404'
          },
          component:() => import('@/pages/404.vue'),
        },
      ]
    },
    {
      path: "/kimcLT",
      name: "kimcLT",
      component:() => import('@/pages/kimcLT.vue')
    },
    {
      path: "/kimcWL",
      name: "kimcWL",
      component:() => import('@/pages/kimcWL.vue')
    }
  ],
  scrollBehavior (to, from) {
    if (to.path.split("/")[1]!=from.path.split("/")[1]) {
      document.body.scrollIntoView();
    } else if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ behavior: 'auto'}) //smooth
        }
      }, 300)
      // return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  }
})
export default router