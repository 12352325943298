// plugins/deviceRwd.js
export default {
    install: (app) => {

        app.provide('deviceRwd')
        app.mixin({
            data() {
                return {
                    deviceMode: String,
                    ACWS: true,
                }
            },
            mounted() {
                this.$nextTick(() => {
                    window.addEventListener('resize', this.isDevice);
                })
            },
            beforeUnmount() {
                window.removeEventListener('resize', this.isDevice);
            },
            created() {
                this.isDevice();
                this.CWS();//(1)模擬正式站 (0/空)自動依照站台切換
            },
            methods: {
                // --- 正式測試站判斷 ---
                CWS(WS=null){
                    if(WS!=1){
                        if(location.href.indexOf("dev")!=-1 || location.href.indexOf("localhost")!=-1){
                            this.ACWS = false;
                        }
                    }else{
                        this.ACWS = true;
                    }
                },
                // --- Device Rwd ---
                isDevice() {
                    if (window.innerWidth >= 1200) {
                        this.deviceMode = 'deskTop';
                    } else if (window.innerWidth < 1200 && window.innerWidth >= 768) {
                        this.deviceMode = 'pad';
                    } else {
                        this.deviceMode = 'mobile';
                    }
                }
            }
        })
    }
}